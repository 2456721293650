import React from 'react'
import BlogCard from '../blog-card'
import "./index.css";



function index({ blogs, includeHeader = false }) {

  return (
    <div className='blog'>
      {includeHeader && <h2>Our Blog </h2>}

      <div className="blog__container">
        {blogs.map((blog) => {
          // console.log("Blog slug is ",blog.slug);
          return (
            <div key={blog.id}>
              <BlogCard blog={blog} />
            </div>

          )
        })}
      </div>

      {/* <Link to={'/blogs/'} className="blog__readmore">
          <span className="font-bold italic">Read More</span>
          <BiChevronRightCircle className='chev_icon ml-1' />
        </Link> */}


    </div>

  )
}

export default index