import { Link } from 'gatsby';
import React from 'react'
import "./index.css";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";

function index({ pageContext }) {

  const { previousPagePath, nextPagePath, pageNumber, numberOfPages, slug } =
    pageContext;


  let prefix = `/blog/`;
  if (slug) {
    prefix = `/category/${slug}/`
  }
  // console.log("prefix", prefix);

  return (
    <div className='pagination'>
      <ul className='pagination__container'>
        <li>
          <PaginationButton link={`${previousPagePath}`} variant="prev" />
        </li>
        {
          Array.from(
            { length: numberOfPages }, (_, i) => i + 1).map(
              num => {
                let link = `${prefix}`;
                if (num > 1) {
                  // E.g /blog/1
                  link += `${num}/`;
                }
                return <li key={link}>
                  <PaginationButton
                    link={link}
                    title={num}
                    isActive={pageNumber + 1 === num} />

                </li>;
              }

            )
        }
        <li>
          <PaginationButton link={`${nextPagePath}`} variant="next" />
        </li>
      </ul>



    </div>

  )
}

const PaginationButton = ({ link, title, isActive, variant }) => {

  const active = isActive ? "active" : "";
  // console.log(title, isActive, active);
  let content = <span>{title}</span>;
  if (variant === "prev") {
    title = "";
    content = <BiChevronLeft size={22}/>
  }
  if (variant === "next") {
    title = "";
    content = <BiChevronRight size={22}/>
  }

  if (!link || link.length <= 1) {
    return <div className="btn disable">{content}</div>;
  }
  return (

    <Link to={link}>
      <div className={`btn ${active}`}>{content}</div>
    </Link>


  )
}

export default index