import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import "./index.css";



function index({ blog }) {

    return (
        <div>
            <div className='blogCard'>
                <Link className="" to={`/${blog.slug}/`}>
                    <GatsbyImage
                        className="blogCard__image"
                        alt={blog.cover.alt}
                        title={blog.cover.alt}
                        image={blog.cover.gatsbyImage}>
                    </GatsbyImage>
                    <p className="blogCard__date">{blog.date}</p>
                </Link>
                <div className="blogCard__tags">{blog.tags.map(tag => <Link key={tag.slug} to={`/category/${tag.slug}/`}><span className='blogCard__tag'>{tag.name}</span></Link>)}</div>
                <Link className="" to={`/${blog.slug}/`}>
                    <p className="blogCard__title"> {blog.title}</p>
                </Link>
            </div>

        </div>


    )
}

export default index